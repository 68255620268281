import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Code } from "react-content-loader";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ErrorPage } from "../components/ErrorPage";
import { FooterNav } from "../components/FooterNav";
import { Menu } from "../components/Menu";
import { ModalComponent } from "../components/Modals";
import { LIST_ROOT_HARVESTS } from "../queries";
import { CategoryRoute } from "./CategoryRoute";
import { MainRoute } from "./MainRoute";
import { PdpRoute } from "./PdpRoute";
import { SearchRoute } from "./SearchRoute";
import { ThemeProvider } from "@emotion/react";
import {
  ThemeProvider as MuiThemeProvider,
  useTheme,
} from "@mui/material/styles";
import { ContextProvider } from "../context";
import { createTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { useConfiguration } from "../hooks/useConfiguration";
import { theme } from "../theme";
import { CartRoute } from "./CartRoute";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryComponent } from "../components/ErrorBoundaryComponent";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ServicesRoute } from "./ServicesRoute";
import { DepartmentsRoute } from "./DepartmentsRoute";
import { Navigation } from "../components/Navigation";
import { BottomNavigationBar } from "../components/BottomNavigation/BottomNavigationBar";
import { BrandRoute } from "./BrandRoute";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    ...theme.kroger.lightTheme,
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    ...theme.kroger.darkTheme,
  },
});

export const AppRoutes = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      const darkMode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setIsDarkMode(darkMode);
      console.log(darkMode ? "Dark Mode is enabled" : "Light Mode is enabled");
    };

    checkDarkMode();

    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    darkModeMediaQuery.addEventListener("change", checkDarkMode);

    return () => {
      darkModeMediaQuery.removeEventListener("change", checkDarkMode);
    };
  }, []);

  const selectedTheme = isDarkMode ? darkTheme : lightTheme;

  return (
    <BrowserRouter>
      <ContextProvider>
        <MuiThemeProvider theme={selectedTheme}>
          <Routes>
            <Route
              path="*"
              element={
                <ErrorPage errorText="No such page! Please try another one." />
              }
            />

            <Route path=":storeId" element={<SelectHarvest />} />

            <Route
              path="/:storeId/:harvestId"
              element={
                <Layout>
                  <MainRoute />
                </Layout>
              }
            />

            <Route path=":storeId/:harvestId/search/:termId">
              <Route
                path=":pageNr"
                element={
                  <Layout>
                    <SearchRoute />
                  </Layout>
                }
              />
              <Route
                path=""
                element={
                  <Layout>
                    <SearchRoute />
                  </Layout>
                }
              />
            </Route>

            <Route path=":storeId/:harvestId/category/:categoryId">
              <Route
                path=":pageNr"
                element={
                  <Layout>
                    <CategoryRoute />
                  </Layout>
                }
              />
              <Route
                path=""
                element={
                  <Layout>
                    <CategoryRoute />
                  </Layout>
                }
              />
            </Route>

            <Route
              path=":storeId/:harvestId/ips/:productId"
              element={
                <Layout>
                  <PdpRoute />
                </Layout>
              }
            />

            <Route
              path=":storeId/:harvestId/ip/:categoryId/:productId"
              element={
                <Layout>
                  <PdpRoute />
                </Layout>
              }
            />

            <Route
              path=":storeId/:harvestId/cart"
              element={
                <Layout>
                  <CartRoute />
                </Layout>
              }
            />
            <Route
              path=":storeId/:harvestId/cart/checkout"
              element={
                <Layout>
                  <CartRoute />
                </Layout>
              }
            />
            <Route
              path=":storeId/:harvestId/services"
              element={
                <Layout>
                  <ServicesRoute />
                </Layout>
              }
            />
            <Route
              path=":storeId/:harvestId/departments"
              element={
                <Layout>
                  <DepartmentsRoute />
                </Layout>
              }
            />
            <Route
              path=":storeId/:harvestId/brand/:brand"
              element={
                <Layout>
                  <BrandRoute />
                </Layout>
              }
            ></Route>
          </Routes>
        </MuiThemeProvider>
      </ContextProvider>
    </BrowserRouter>
  );
};

const Layout = ({ children }: { children: any }) => {
  const muiTheme = useTheme();
  const { storeId = "", harvestId = "", termId } = useParams();
  const { loading: configLoading, eRetailer } = useConfiguration({
    storeId,
    harvestId,
  });

  if (configLoading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />{" "}
      </div>
    );
  } else {
    const shop = eRetailer?.toLowerCase();

    return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
        <ThemeProvider theme={theme[shop || ""] || {}}>
          <AppStyled>
            <Navigation />
            <Menu />
            <InnerContainer theme={muiTheme}>{children}</InnerContainer>
            <ModalComponent />
          </AppStyled>
        </ThemeProvider>
        <BottomNavigationBar storeId={storeId} harvestId={harvestId} />
      </ErrorBoundary>
    );
  }
};

const AppStyled = styled.div`
  position: relative;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: red;

  nav {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  * {
    box-sizing: border-box;
  }
  .inner-container {
  }
`;
export const InnerContainer = styled.div<{ theme: any }>`
  max-width: 1128px;
  flex: 1;
  background: ${({ theme }) => theme.palette.background};
  /* color: ${({ theme }) => theme.palette.font}; */
`;

const SelectHarvest = () => {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(LIST_ROOT_HARVESTS, {
    variables: {
      fullpath: `/ePog Product Data/Products/${storeId}`,
    },
    skip: !storeId,
  });

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    const harvests = data?.getObjectFolder?.children;

    return (
      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <List>
          {harvests.map((harvest: any) => (
            <ListItem
              key={harvest?.key}
              disablePadding
              secondaryAction={
                <IconButton
                  onClick={() => navigate(`/${storeId}/${harvest?.key}`)}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
              }
            >
              <ListItemButton
                onClick={() => navigate(`/${storeId}/${harvest?.key}`)}
              >
                <ListItemText primary={harvest?.key} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
};
