import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT, CATEGORY_FRAGMENT } from "./fragments";

export const GET_PRODUCT_CATEGORY_BY_TERM = ({ termId, suggest }: any) => {
  if (suggest) {
    const searchKeyword = termId.replace("sk=", "");
    return gql`
  {
    getEPogTermListing(
      filter: "{\\"Name\\": {\\"$like\\" :\\"%${searchKeyword}%\\"}}"
    ) {
      edges {
        node {
          key
          id
          fullpath
          TermImage {
            fullpath
          }
        }
      }
    }
  }
`;
  } else {
    if (termId && termId.includes("sk=")) {
      const searchKeyword = termId.replace("sk=", "");
      return gql`
    ${PRODUCT_FRAGMENT}
  {
    getEPogTermListing(
      filter: "{\\"Name\\": \\"${searchKeyword}\\"}",
    ) {
      edges {
        node {
          id
          fullpath
          key
          Products {
           ...GenericProduct
           ... on object_ePog {
               Variants {
            ... GenericProduct
             }
              VariantsByType {
                ... on fieldcollection_VariantsByType {
                  VariantType
                  ProductVariants {
                      ...GenericProduct
                  }
                }
        }
               Upsel {
            ... GenericProduct
             }
            }
            ...on object_ListPromoContent {
              Video {
                title
                data {
                  ...on asset {
                    fullpath
                  }
                }
              }
            }
          }
         GenericFilters {
                ... on fieldcollection_Brand {
                BrandName
          }
        }  
        PillFilters{
          ... on object_ProductField {
            Name
          }
        }
        PillFiltersWithImage{
          ... on object_PillFilter {
            Name
            Thumbnail {
              fullpath
            }
          }
        }
        BrandFilterElements {
          ... on fieldcollection_BrandFilterElement {
            BrandName
          }
        },
        CategoriesFilterElements {
          ... on fieldcollection_CateogriesFilterElement {
            CategoryName
          }
        }
        TermImage{
          fullpath
        }
        TermBannerImage { 
          fullpath
        }
        TermBannerVideo {
          data {
            ...on asset {
              fullpath
            }
          }
        }
        TermBannerText
        TermBannerVideoPosition
        TermBannerProducts {
          ... on object_ePog {
            key
            Name
            Price
            Brand
            RRAmount
            RROverall
            RegularPrice
            Gallery {
              image {
                fullpath
              }
            }
          }
        }
        TermBannerSponsoredBy
        AddProductButton
        OptionsButton
        PDPAddToCartButton
        }
      }
    }
  }
`;
    } else {
      //termId = termId?.split('__')[0]
      return gql`
    ${PRODUCT_FRAGMENT}
    {
      getEPogTermListing(ids: \"${termId}\" ) {
        edges {
          node {
            key
            id
            Name
             BrandFilters{
            ... on fieldcollection_Brand {
              BrandName
            }
          }
            Products {
              ...GenericProduct
              ... on object_ePog {
                Variants {
                  ...GenericProduct
                }
                 VariantsByType {
          ... on fieldcollection_VariantsByType {
            VariantType
            ProductVariants {
                ...GenericProduct
            }
          }
        }
                Upsel {
                  ...GenericProduct
             }
              }
              ...on object_ListPromoContent {
                Video {
                  title
                  data {
                    ...on asset {
                      fullpath
                    }
                  }
                }
              }
            }
            GenericFilters {
                ... on fieldcollection_Brand {
                BrandName
              }
            }
            PillFilters{
              ... on object_ProductField {
                Name
              }
            }
            PillFiltersWithImage{
              ... on object_PillFilter {
                Name
                Thumbnail {
                  fullpath
                }
              }
            }
            BrandFilterElements {
              ... on fieldcollection_BrandFilterElement {
                BrandName
              }
            },
            CategoriesFilterElements {
              ... on fieldcollection_CateogriesFilterElement {
                CategoryName
              }
            }
            fullpath
            AddProductButton
            OptionsButton
            PDPAddToCartButton
            TermBannerImage { 
              fullpath
            }
            TermImage{
              fullpath
            }
            TermBannerVideo {
              data {
                ...on asset {
                  fullpath
                }
              }
            }
            TermBannerText
            TermBannerVideoPosition
            TermBannerProducts{
            ... on object_ePog{
              id
              key
              Name
              Brand
              RegularPrice
              Price
              RRAmount
              RROverall
              Gallery{
                image{
                  fullpath
                }
              }
            }
          }
            TermBannerSponsoredBy
             children {
             ... on object_ePogTerm {
            key
            id
            fullpath
            key
                TermImage {
            fullpath
          }
          }
        }
          }
        }
      }
    }
  `;
    }
  }
};
export const GET_BRANDPAGE_BY_ID = gql`
query GetBrandPage($fullpath: String) {
  getBrandPage(fullpath: $fullpath) {
    Title
    TopContent
    Products {
		... on object_ePog {
        key
        Name
        Gallery {
          image {
            fullpath
          }
        }
        id
        Price
        RegularPrice
      }
    }
    BottomContent
  }
}
`;
export const LIST_ALL_TERMS = gql`
  {
    getEPogTermListing {
      edges {
        node {
          key
          fullpath
          id
          TermImage {
            fullpath
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  ${PRODUCT_FRAGMENT}
  query GetEPog($getEPogId: Int) {
    getEPog(id: $getEPogId) {
      ...GenericProduct
      ... on object_ePog {
        Variants {
          ...GenericProduct
        }
        VariantsByType {
          ... on fieldcollection_VariantsByType {
            VariantType
            ProductVariants {
              ...GenericProduct
            }
          }
        }
        Upsel {
          ...GenericProduct
        }
      }
    }
  }
`;

export const LIST_ROOT_HARVESTS = gql`
  query GetObjectFolder($fullpath: String) {
    getObjectFolder(fullpath: $fullpath) {
      children {
        ... on object_Harvest {
          HarvestID
          key
        }
      }
    }
  }
`;

export const LIST_ROOT_CATEGORIES = gql`
  query GetObjectFolder($fullpath: String) {
    getObjectFolder(fullpath: $fullpath) {
      children {
        ... on object_ePogTerm {
          key
          fullpath
          id
          TermImage {
            fullpath
          }
        }
      }
    }
  }
`;

export const GET_CONFIGURATION = gql`
  query GetConfiguration($fullpath: String) {
    getHarvest(fullpath: $fullpath) {
      id
      Template {
        ... on object_SiteTemplate {
          ProductFields {
            ... on object_ProductField {
              Name
            }
          }
          PDPFields {
            ... on object_ProductField {
              Name
            }
          }

          ShipmentMethod {
            ... on object_ProductField {
              Name
            }
          }
          HomePageTemplate {
            ... on object_HomePage {
              fullpath
            }
          }

          id
          FilterType
          PillFiltersWithImageDisplayStyle
          SortByEnable
          Currency
          Columns
          Rows
          AddProductButton
          AddPosition
          eRetailer
          FilterVisibility
          FilterPosition
          CarouselWidth
          ShipmentMethods
          CheckoutButtonPosition
          OptionButtonVisibility
          AddProductButtonFunctionality
          SecondLineElementWidth
          SecondLineSliderName
          SecondLineProductsExpositionName
          ProductCardBorderType
          LanguageVersion
          SponsoredPosition
          SecondLineSliderProducts {
            ... on object_ePog {
              key
              Name
              Gallery {
                image {
                  fullpath
                }
              }
              id
              Price
              RegularPrice
            }
          }
          SecondLineExpositionProducts {
            ... on object_ePog {
              key
              Name
              Gallery {
                image {
                  fullpath
                }
              }
              id
              Price
              RegularPrice
            }
          }
          CarouselItems {
            ... on object_CarouselItem {
              Title
              Content
              ButtonLabel
              Image {
                fullpath
              }
            }
          }
          Logo {
            fullpath
          }
        }
      }
    }
  }
`;

export const GET_SIMILAR_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query GetAllProducts($fullpath: String) {
    getHarvest(fullpath: $fullpath) {
      children {
        ...GenericProduct
      }
    }
  }
`;

export const GET_ALL_CATEGORY_PRODUCTS = gql`
  ${CATEGORY_FRAGMENT}
  query GetAllCategoryProducts($id: String) {
    getEPogTermListing(ids: $id) {
      edges {
        node {
          ...RecursiveCategory
        }
      }
    }
  }
`;

export const GET_HARVEST_TERMS = gql`
  ${CATEGORY_FRAGMENT}
  query GetHarvestTerms($fullpath: String) {
    getHarvest(fullpath: $fullpath) {
      children {
        ...RecursiveCategory
      }
    }
  }
`;
export const GET_HOME_PAGE_BY_ID = gql`
query GetBrandPage($fullpath: String) {
    getHomePage(fullpath: $fullpath) {
      id
      Styles
      Elements {
        ... on fieldcollection_HomePageElementRT {
          __typename
          Content
        }
        ... on fieldcollection_HomePageElementCarousel {
          __typename
          Title
          CarouselItems {
            ... on object_CarouselItem{
              Title
              Content
              ButtonLabel
              Image {
                fullpath
              }
            }
          }
        }
        ... on fieldcollection_HomePageElementSlider {
          __typename
          Title
          SliderProducts{
          ... on object_ePog {
              key
              Name
              Gallery {
                image {
                  fullpath
                }
              }
              id
              Price
              RegularPrice
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCALIZED = gql`
  query getLocalization($defaultLanguage: String) {
    getLocalization(
      fullpath: "/Translations/Translations"
      defaultLanguage: $defaultLanguage
    ) {
      Items
      OpenDetails
      HideDetails
      SoldBy
      Remove
      SaveForLater
      ContinueToCheckout
      Subtotal
      Taxes
      CalculatedAtCheckout
      EstimatedTotal
      AdditionalInfoHere
      ProductDetails
      CustomersAlsoConsidered
      RecommendedForYou
      PairWellTogether
      CustomerReviewsRatings
      AllReviews
      SomethingWentWrong
      TryAgain
      Clear
      ViewResults
      BrandsAreYouInterestedIn
      CategoriesAreYouInterestedIn
      YourPriceRange
      SpecialOffersAreYouInterestedIn
      Variants
      AllFilters
      Reset
      Update
      AllRightsReserved
      StoreLocations
      Departments
      Services
      SignIn
      GetItInDays
      GetItIn2Hours
      GetItIn3Hours
      AddToList
      ShippingMethod
      ActualVariant
      OutOf
      Reviewers
      SeeAllReviews
      WriteAReview
      SortBy
      Rating
      VerifiedPurchaser
      PopularFilters
      Options
      ResultsFor
      PopularProducts
      PopularIn
      PopularSections
      Deals
      NewProducts
      SearchInStore
      Price
      Brand
      Categories
      SpecialOffers
      CartIsEmpty
      Cart
      ShowProductWithVariants
      FourOrMore
      ThreeOrMore
      TwoOrMore
      OneOrMore
      Sort
      AlphabetAZ
      AlphabetZA
      PriceLH
      PriceHL
      RatingLH
      RatingHL
      FooterLinks
      AddToCart
      AboutThisItem
      Stars
      None
      ArrivesBy
      ShippingArrives
      UpdateCart
      CheckoutMessage
      ReviewCart
    }
  }
`;
