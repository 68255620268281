import { Code } from "react-content-loader";
import { HomeCategoriesSlider } from "./HomeCategoriesSlider";
import footer_1 from "../../../assets/images/footer_1.png";

import styled from "@emotion/styled";
import { HomeOffersSlider } from "./HomeOffersSlider";
import { HomeProductsSliderHeader } from "./ProductsSlider/HomeProductsSliderHeader";
import { useMainRoute } from "./useMainRoute";
import { PopularProducts } from "../../components/PopularProducts";
import { Carousel } from "../../components/Carousel";
import DOMPurify from "dompurify";

const sanitizedData = (data: any) => ({
  __html: DOMPurify.sanitize(data, { FORBID_TAGS: ['script'] }),
});

export const MainRoute = () => {
  const {
    homePage,
    loading
  }: { loading: any; homePage: any; } = useMainRoute();

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    return (
      <StyledComponentWrapper>
        <HomeOffersSlider />
        <HomeProductsSliderHeader
          header="Shop Categories"
          subHeader="Shop All"
        />
        <HomeCategoriesSlider />

        {homePage && (
          <style>{homePage.Styles}</style>
        )}
        {
          homePage && (
            homePage.Elements.map((item: any, index: number) => {
              if (item.__typename == 'fieldcollection_HomePageElementSlider') {
                return (<PopularProducts products={item.SliderProducts} title={item.Title}></PopularProducts>);
              }
              if (item.__typename == 'fieldcollection_HomePageElementCarousel') {
                return (<div className="carousel-container">
                  <Carousel CarouselItems={item.CarouselItems}></Carousel>
                </div>);
              }
              if (item.__typename == 'fieldcollection_HomePageElementRT') {
                return (<div dangerouslySetInnerHTML={sanitizedData(item.Content)} className='top'>
                </div>);
              }
            })
          )
        }

        <img
          src={footer_1}
          alt=""
          style={{ width: "100%", borderRadius: "12px", objectFit: "contain" }}
        />
        <div style={{ marginBottom: "70px" }}></div>
      </StyledComponentWrapper>
    );
  }
};

const StyledComponentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  margin-top: 78px;
  padding: 0px 12px;
`;
