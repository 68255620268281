import { ApolloError } from '@apollo/client';

export interface Iconfig {
  Columns?: number;
  Rows?: number;
  AddPosition?: AddPositionEnum;
  eRetailer?: string;
  FilterVisibility?: string;
  FilterPosition?: string;
  CarouselWidth?: string;
  CheckoutButtonPosition?: CheckoutButtonPositionEnum;
  SecondLineElementWidth?: string;
  SecondLineSliderName?: string;
  SecondLineProductsExpositionName?: string;
  Logo?: { fullpath?: string };
  CarouselItems?: IcarouselItem;
  loading?: boolean;
  error?: ApolloError;
  ProductFields?: IProductField;
  PillFilters?: IFilters;
  BrandFilterElements?: IFilters;
  CategoriesFilterElements?: ICategoriesFilter;
  PDPFields?: IpdpField;
  SecondLineSliderProducts?: IsliderProduct;
  SecondLineExpositionProducts?: IsliderProduct;
  ProductCardBorderType?: ProductCardBorderEnum;
  SponsoredPosition?: string,
  AddProductButton?: string;
  AddProductButtonFunctionality?: IAddProductButtonFunctionality;
  OptionButtonVisibility?: IOptionButtonVisibilty;
  Currency?: TCurrency;
  ShipmentMethods?: TShipmentMethods;
  ShipmentMethod?: IShipmentMethod[];
  LanguageVersion?: string;
  FilterType?: string;
  PillFiltersWithImageDisplayStyle?: string;
  SortByEnable?: boolean;
  HomePageTemplate?: { fullpath?: string };
}

export interface IsliderProduct {
  key: string;
  Name: string;
  Gallery: Igallery[];
  id: string;
  Price: number;
  RegularPrice: number;
}

export type TCurrency = '$' | '€' | '£';

interface Igallery {
  image: {
    fullpath: string;
  };
}

interface IcarouselItem {
  Title: string;
  Content: string;
  ButtonLabel: string;
  Image: { fullpath?: string };
}

export enum AddPositionEnum {
  Left = 'Left',
  Right = 'Right',
  EmptyString = '',
}

export enum ProductCardBorderEnum {
  shadow = 'shadow',
  border = 'border',
  none = 'none',
}

export enum CheckoutButtonPositionEnum {
  Top = 'top',
  Bottom = 'bottom',
}

export type TShipmentMethods = 'shipping' | 'pickup' | 'delivery';

export interface IShipmentMethod {
  Name: TShipmentMethods;
}

export interface IProductField {
  Name: ProductFieldName;
}

export interface IFilters {
  Name: FilterName;
}

export interface ICategoriesFilter {
  CategoryName: string;
}

export interface IpdpField {
  Name: PdpFieldName;
}

export enum IOptionButtonVisibilty {
  visible = 'visible',
  hidden = 'hidden',
}

export enum IAddProductButtonFunctionality {
  textAndStepper = 'textAndStepper',
  stepper = 'stepper',
  text = 'text',
}

export enum PdpFieldName {
  'Description' = 'Description',
  ProductYouMakeLike = 'ProductYouMakeLike',
  Reviews = 'Reviews',
  Recommended = 'Recommended',
}
export enum ProductFieldName {
  'Add To Cart' = 'Add To Cart',
  Brand = 'Brand',
  Rating = 'Rating',
  Name = 'Name',
  Price = 'Price',
  PromoContent = 'PromoContent',
  Sponsored = 'Sponsored'
}
export enum FilterName {
  'Add To Cart' = 'Add To Cart',
  Brand = 'Brand',
  Rating = 'Rating',
  Name = 'Name',
  Price = 'Price',
  PromoContent = 'PromoContent',
  Variants = 'Variants'
}
