import React from "react";
import { PopularSections } from "../../components/PopularSections";
import { ICategory } from "../../types";
import { useMainRoute } from "./useMainRoute";
import { useQuery } from "@apollo/client";
import { LIST_ALL_TERMS } from "../../queries";
import { useParams } from "react-router-dom";

export const HomeCategoriesSlider = () => {
  const { storeId, harvestId } = useParams();

  const { data, loading } = useQuery(LIST_ALL_TERMS);

  const [categories, setCategories] = React.useState();

  React.useEffect(() => {
    if (!loading) {
      const edges = data?.getEPogTermListing?.edges;

      const categories = edges
        ?.filter(
          (edge: any) =>
            edge?.node?.fullpath?.includes(storeId) &&
            edge?.node?.fullpath?.includes(harvestId) &&
            edge?.node?.fullpath?.includes("Category")
        )
        .map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: any, b: any) => a.sort - b.sort)
        .map(({ value }: any) => value);
      setCategories(categories);
    }
  }, [loading, data, harvestId, storeId]);
  return <>{categories && <PopularSections categories={categories}></PopularSections>}</>;
};
